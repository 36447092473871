import React from "react";
// import loadingSpinner from "../publicSite/images/loading-spinner.svg";
import { Spin } from "antd";


function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        minWidth: "200px",
        minHeight: "200px",
      }}
    >
      <Spin size="large"/>
    </div>
  );
}

export default Loading;
