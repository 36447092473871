import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import PublicSite from "./publicSite/public-site";
// import Admin from "./admin/admin";
import Login from "./admin/pages/login/login";
import "./analytics.js";
import Loading from "./components/loading";
import LocationTest from "./publicSite/pages/locastion-test/location-test";

function App() {

  const Admin = lazy(() => import("./admin/admin"));
  const PublicSite = lazy(() => import("./publicSite/public-site"));

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/loctest" element={<LocationTest />} />
          <Route
            path="/loctest.html"
            element={<Navigate to="/loctest" replace />}
          />

          <Route
            path="/leads-portal"
            element={<Navigate to="/admin" replace />}
          />
          <Route path="/*" element={<PublicSite />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
