import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyC4SABdj4OIWCRomzLnRCAWm7oqF1PBczY",
  authDomain: "run4lives.firebaseapp.com",
  projectId: "run4lives",
  storageBucket: "run4lives.appspot.com",
  messagingSenderId: "438810393400",
  appId: "1:438810393400:web:d46a25371c2da4801c1fcd",
  measurementId: "G-4321E4HSLF",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const func = getFunctions(app);

export { db, auth, func };
