import { Button, Card, Flex, Input, Select, Space, Typography, message } from "antd";
import React, { useState, useEffect } from "react";
import styles from "./location-test.module.scss";
import Loading from "../../../components/loading";
import { getFunctionsHost } from "../../../utils/paths";
import { countries, countrySelectOptions, usStateSelectOptions, usStates } from "../../../utils/states";


export default function LocationTest() {
  const [tool, setTool] = useState("device-gps");
  const [isLoading, setIsLoading] = useState(false);
  const [coords, setCoords] = useState<number[]>([0, 0]);
  const [locationData, setLocationData] = useState<any>(null);
  const [manualAddress, setManualAddress] = useState<{city: string, state: string, country: string}>({city: "", state: "", country: ""});

  const host = getFunctionsHost();

  /**
   * IP
   * @returns {Promise<Object>} the location data.
   */
  async function getIpBasedLocationData() {
    setIsLoading(true);
    const requestPath = `${host}getIpBasedLocationData`;
    console.log(requestPath);

    try {
      const response = await fetch(requestPath, {
        headers: { Accept: "application/json" },
      });

      if (!response.ok) throw new Error("Network response was not ok.");
      const res = await response.json();
      setLocationData(res);
      setIsLoading(false);
    } catch (error: any) {
      console.error("Error in getIpBasedLocationData: " + error.message);
      throw error; // Rethrow the error for further handling if necessary
      setIsLoading(false);
    }
  }

  /**
   * LAT,LON
   * @param {number} lat Latitude
   * @param {number} lon Longitude
   * @returns {Promise<Object>} the location data.
   */
  async function getCoordBasedLocationData(lat: number, lon: number) {
    setIsLoading(true);
    const requestPath = `${host}getGpsBasedLocationData?lat=${lat}&lon=${lon}`;
    console.log(requestPath);
    try {
      const response = await fetch(requestPath);
      console.log(response);
      if (!response.ok) throw new Error("Network response was not ok.");
      const res = await response.json();
      res.coords = `${lat},${lon}`;
      setLocationData(res);
      setIsLoading(false);
    } catch (error: any) {
      console.log("Error in getCoordBasedLocationData: " + error.message);
      setIsLoading(false);
    }
  }

  /**
   * DEVICE GPS
   * @returns {Promise<Object>} the location data.
   */
  async function getGpsBasedLocationData() {
    // document.getElementById("gpsLocation").innerHTML = "Loading...";
    setIsLoading(true);
    if (navigator.geolocation) {
      try {
        const position: any = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: false,
            timeout: 15000,
            maximumAge: 0,
          });
        });

        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        getCoordBasedLocationData(lat, lon);
      } catch (error: any) {
        console.log("Error in getGpsBasedLocationData: " + error.message);
        setIsLoading(false);
      }
    } else {
      console.log("Geolocation is not supported by this browser.");
      setIsLoading(false);
    }
  }

  /**
   * MANUAL CITY/COUNTRY
   * @returns {Promise<Object>} the location data.
   */
  async function getCityCountryLocationData(
    city?: string,
    state?: string,
    country?: string
  ) {
    if (!city || !country) {
      alert("City or country missing");
      return;
    }
    setIsLoading(true);
    const requestPath = `${host}getSpecificLocationData?city=${city}&state=${state}&country=${country}`;
    try {
      const response = await fetch(requestPath, {
        headers: { Accept: "application/json" },
      });
      if (!response.ok) throw new Error("Network response was not ok.");
      const res = await response.json();
      setLocationData(res);
      setIsLoading(false);
    } catch (error: any) {
      console.error("Error in getSpecificLocationData: " + error.message);
      throw error; // Rethrow the error for further handling if necessary
      setIsLoading(false);
    }
  }


  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  return (
    <div className={styles.locationTest}>
      <h1>Location Test Tool</h1>
      <Select
        size="large"
        style={{ width: "200px" }}
        options={[
          { label: "Device GPS test", value: "device-gps" },
          { label: "Manual coords test", value: "manual-coords" },
          { label: "IP test", value: "ip" },
          { label: "Manual address test", value: "manual-address" },
        ]}
        defaultValue={"device-gps"}
        onChange={(value) => {
          setTool(value);
          setLocationData(null);
        }}
      />
      <Card className={styles.card}>

        {tool === "device-gps" && (
          <Button size="large" type="primary" onClick={getGpsBasedLocationData}>
            Allow Location
          </Button>
        )}

        {tool === "manual-coords" && (
          <Flex vertical gap="middle">
            <Input
              size="large"
              name="coords"
              placeholder="lat,lon"
              onChange={(e) =>
                setCoords(
                  e.target.value.split(",").map((c) => parseFloat(c.trim()))
                )
              }
            />
            <Button
              size="large"
              type="primary"
              onClick={() => {
                if (!coords || coords.length < 2 || isNaN(coords[0]) || isNaN(coords[1])) {
                  message.error("Coords missing or invalid");
                  return;
                }
                  getCoordBasedLocationData(coords[0], coords[1]);
              }}
            >
              Get Location
            </Button>
          </Flex>
        )}

        {tool === "ip" && (
          <Button size="large" type="primary" onClick={getIpBasedLocationData}>
            Get Location
          </Button>
        )}

        {tool === "manual-address" && (
          <Flex vertical gap="middle">
            <Input
              size="large"
              placeholder="City"
              onChange={(e) => {
                setManualAddress({...manualAddress, city: e.target.value});
              }}
              />
            <Select
              showSearch
              size="large"
              placeholder="State"
              options={usStateSelectOptions}
              filterOption={filterOption}
              onChange={(e) => {
                setManualAddress({...manualAddress, state: e});
              }}
            />
            <Select
              showSearch
              size="large"
              placeholder="Country"
              options={countrySelectOptions}
              filterOption={filterOption}
              onChange={(e) => {
                setManualAddress({...manualAddress, country: e});
              }}
            />
            <Button
              size="large"
              type="primary"
              onClick={() => {
                const {city, state, country} = manualAddress;
                getCityCountryLocationData(city, state, country);
              }}
            >
              Get Location
            </Button>
          </Flex>
        )}

        <div>{isLoading ? <Loading /> : LocationDataDisplay(locationData)}</div>
      </Card>
    </div>
  );
}

function LocationDataDisplay(locationData: any) {
  if (!locationData) return null;
  const location = locationData.location;
  if (!location) return null;
  const groups = [
    ...locationData.whatsappGroups.local,
    ...locationData.whatsappGroups.nearby,
  ];
  console.log(groups);
  return (
    <>
      <Typography.Title level={4}>
        {location.city && (
          <>
            {location.city}
            <br />
          </>
        )}
        {location.state && (
          <>
            {location.state}
            <br />
          </>
        )}
        {location.country && (
          <>
            {location.country}
            <br />
          </>
        )}
        {locationData.coords && (
          <>
            <a href={"https://www.google.com/maps/place/"+locationData.coords}>{locationData.coords}</a>
            <br />
          </>
        )}
      </Typography.Title>
      {
        groups.length > 0 && groups.map((group) => BranchButton(group))
        // <Space size={[8, 16]} align="center"  wrap>
        //   {groups.map((group) => BranchButton(group))}
        // </Space>
      }
    </>
  );
}

function BranchButton(branchData: any) {
  console.log(branchData.label);
  return (
    <Button
      type="default"
      style={{ margin: "4px" }}
      onClick={() => window.open(branchData.group_url, "_blank")}
    >
      {branchData.label}
    </Button>
  );
}
