import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth, db } from "../../../utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";
import googleIcon from "../../images/icon-google.svg";
import "./login.scss";

function Login() {
  const [user, setUser] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        const userRef = doc(db, "users", fbUser.email!);
        const userDoc = await getDoc(userRef);
        console.log(userDoc);
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          setError(fbUser.email + ' not found');
        }
      }
    });
  }, []);

  function handleSignInClick() {
    const provider = new GoogleAuthProvider();
    // signInWithRedirect(auth, provider);
    signInWithPopup(auth, provider);
  }

  if (user) {
    return <Navigate to="/admin/branches" />;
  }

  return (
    <div id="loginWrapper">
      <div id="loginCard">
        <h1>Group Leads Login</h1>
        <p>
          This portal is for registered group leads only.
          <br />
          If you're looking to start a new group in your location,
          <br />
          <a href="/">Use the homepage to join</a> first.
        </p>
        <button id="googleLoginButton" onClick={handleSignInClick}>
          <img src={googleIcon} />
          Sign in with Google
        </button>
        {error && <div id="errorMessage">{error}</div>}
      </div>
    </div>
  );
}

export default Login;
